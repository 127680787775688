@import '../../variables.modules.scss';

.portfolio--sub{
    font-size: 1rem;
    padding: 0 10px;
    padding-top: 0 !important;
}

.portfolio{
    padding-top: 0.3rem !important;
}

.Portfolio-Image{
    max-width: 350px;
    border: 3px solid #c9c9c9;
    box-shadow: 0 0 5px 1px;   
}

@media screen and (min-width: 550px) {
    .Portfolio-Image{
        max-width: 500px;  
    }
}